let mutations = {
  updateUser(state, user) {
    Object.assign(state.user, user);
  },
  updateUserPoints(state, userPoints) {
    state.userPoints = Array.from(new Set(userPoints));
  },
  updateUserInfo(state, userInfo) {
    Object.assign(state.userInfo, userInfo);
  },
  setMuteNotifications(state, mute) {
    state.muteNotifications = mute;
    // Object.assign(state.muteNotifications, mute);
  },
  updateUserTickets(state, userTickets) {
    state.userTickets = userTickets;
  },
  updatePointTransactions(state, transactions) {
    state.pointTransactions = transactions;
  }
};

export default mutations;
