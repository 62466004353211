let state = {
  user: {},
  userPoints: {},
  userInfo: {},
  userTickets: [],
  pointTransactions: {},

  muteNotifications: false,
};

export default state;
