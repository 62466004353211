<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
window.addEventListener(
  'resize',
  (function () {
    document.documentElement.style.setProperty(
      '--app-height',
      `${window.innerHeight}px`
    );
  })()
);

export default {
  name: 'App',
  data() {
    return {};
  },
};
</script>
