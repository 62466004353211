import Vue from 'vue';
import Vuex from 'vuex';
import mutations from './mutations';
import getters from './getters';
import state from './state';
import ModuleArticle from './modules/article';
import ModuleNotifications from './modules/notifications';
import ModulePortal from './modules/portal';

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  getters,
  mutations,
  modules: {
    article: ModuleArticle,
    noti: ModuleNotifications,
    portal: ModulePortal,
  },
});
