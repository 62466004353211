import store from '@/store';
import i18n from '@/i18n';

const apiHost = process.env.VUE_APP_API_HOST;

function updateLanguage(setting, lang) {
  if (lang) {
    let language = lang == 'en' ? lang : 'zh-TW';
    localStorage.setItem('lang', language);
    setting.locale = language;
  } else {
    setting.locale = localStorage.getItem('lang');
  }
}

function getUserInfo(userId) {
  let merchantId = store.state.user.user?._json?.merchantId;
  if (!merchantId) throw new Error('no merchantId');
  let config = {
    method: 'get',
    url: `${apiHost}/identities/v1/merchants/${merchantId}/members/${userId}`,
    headers: {},
  };
  return qcsso
    .request(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log('getUserInfo error:', error);
    });
}

function auth(router) {
  router.beforeEach(async (to, from, next) => {
    if (to.path === '/error') {
      return next();
    }

    try {
      await store.dispatch('portal/init');
    } catch (err) {
      console.error(err);
      return next({
        path: '/error',
        query: to.query,
      });
    }

    console.log('beforeEach: ', to, from);
    updateLanguage(i18n, to.query.lang);

    const { portalInfo } = store.state.portal;
    let appId;

    if (to.path === '/line-code') {
      appId = portalInfo.sso?.inLiffAppId;
    } else {
      appId = store.state.userInfo.phoneVerified
        ? portalInfo.sso?.appId
        : portalInfo.sso?.unverifyAppId;
    }

    if (!appId) {
      console.error('Error: no appId');
      return next({
        path: '/error',
        query: to.query,
      });
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      qcsso
        .init({
          appId,
          params: { referralCode: to.query.referralCode || '' },
        })
        .then(() => {
          if (!qcsso.isLoggedIn()) {
            const portalKey = to.query.portal_key;
            const shopId = to.query.shop_id;

            let redirectUrl = `${process.env.VUE_APP_HOST}${
              process.env.BASE_URL
            }${to.path.slice(1)}${`?portal_key=${portalKey}`}`;

            if (shopId) redirectUrl += `&shop_id=${shopId}`;

            qcsso.login({ redirectUrl });
          } else {
            qcsso
              .getProfile()
              .then(function (user) {
                store.commit('updateUser', user);

                if (Object.keys(store.state.userInfo).length !== 0)
                  return to.path == '/'
                    ? next({
                        path: '/home',
                        query: to.query,
                      })
                    : next();

                getUserInfo(user.userId).then(res => {
                  console.log('userInfo ', res);
                  store.commit('updateUserInfo', res);
                  return to.path == '/'
                    ? next({
                        path: '/home',
                        query: to.query,
                      })
                    : next();
                });
              })
              .catch(function (e) {
                console.error(e);
                next({
                  path: '/error',
                  query: to.query,
                });
              });
          }
        });
    } else if (to.matched.some(record => record.meta.pwaCheck)) {
      // 不用登入但是如果登入就跳轉
      qcsso
        .init({
          appId,
          params: { referralCode: to.query.referralCode || '' },
        })
        .then(() => {
          if (!qcsso.isLoggedIn()) {
            next();
          } else {
            next({
              path: '/home',
              query: to.query,
            });
          }
        });
    } else {
      next(); // make sure to always call next()!
    }
  });
}

export default auth;
