export default {
  path: '/',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  meta: {
    requiresAuth: true,
  },
  redirect: () => {
    // the function receives the target route as the argument
    // a relative location doesn't start with `/`
    // or { path: 'profile'}
    //   return 'welcome'
    return 'home';
  },
  components: {
    default: () =>
      import(
        /* webpackChunkName: "AppLayout" */ '../components/Layout/AppLayout.vue'
      ),
  },
  children: [
    {
      path: '/home',
      components: {
        default: () =>
          import(/* webpackChunkName: "Home" */ '../pages/Home.vue'),
        NotificationBar: () =>
          import(
            /* webpackChunkName: "NotificationBar" */ '../components/Layout/NotificationBar.vue'
          ),
      },
    },
    {
      path: '/tickets',
      components: {
        default: () =>
          import(/* webpackChunkName: "MyTickets" */ '../pages/MyTickets.vue'),
        NotificationBar: () =>
          import(
            /* webpackChunkName: "NotificationBar" */ '../components/Layout/NotificationBar.vue'
          ),
      },
    },
    {
      path: '/tickets/:ticketId',
      components: {
        default: () =>
          import(/* webpackChunkName: "Ticket" */ '../pages/Ticket.vue'),
        NotificationBar: () =>
          import(
            /* webpackChunkName: "NotificationBar" */ '../components/Layout/NotificationBar.vue'
          ),
      },
    },
    {
      path: '/profile',
      components: {
        default: () =>
          import(/* webpackChunkName: "Profile" */ '../pages/Profile.vue'),
        NotificationBar: () =>
          import(
            /* webpackChunkName: "NotificationBar" */ '../components/Layout/NotificationBar.vue'
          ),
      },
    },
    {
      path: '/point-record',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "PointRecord" */ '../pages/PointRecord.vue'
          ),
        NotificationBar: () =>
          import(
            /* webpackChunkName: "NotificationBar" */ '../components/Layout/NotificationBar.vue'
          ),
      },
    },
    {
      path: '/stamp-card-record/:stampCardId',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "PointRecord" */ '../pages/StampCardRecord.vue'
          ),
        NotificationBar: () =>
          import(
            /* webpackChunkName: "NotificationBar" */ '../components/Layout/NotificationBar.vue'
          ),
      },
    },
    {
      path: '/profile/edit',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "ProfileEdit" */ '../pages/ProfileEdit.vue'
          ),
        NotificationBar: () =>
          import(
            /* webpackChunkName: "NotificationBar" */ '../components/Layout/NotificationBar.vue'
          ),
      },
    },
    {
      path: '/articles',
      components: {
        default: () =>
          import(/* webpackChunkName: "Articles" */ '../pages/Articles.vue'),
        NotificationBar: () =>
          import(
            /* webpackChunkName: "NotificationBar" */ '../components/Layout/NotificationBar.vue'
          ),
      },
    },
    {
      path: '/articles/:id',
      components: {
        default: () =>
          import(/* webpackChunkName: "Article" */ '../pages/Article.vue'),
        NotificationBar: () =>
          import(
            /* webpackChunkName: "NotificationBar" */ '../components/Layout/NotificationBar.vue'
          ),
      },
    },
    {
      path: '/stores',
      components: {
        default: () =>
          import(/* webpackChunkName: "Stores" */ '../pages/Stores.vue'),
        NotificationBar: () =>
          import(
            /* webpackChunkName: "NotificationBar" */ '../components/Layout/NotificationBar.vue'
          ),
      },
    },
    {
      path: '/carrier/edit',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "CarrierEdit" */ '../pages/CarrierEdit.vue'
          ),
        NotificationBar: () =>
          import(
            /* webpackChunkName: "NotificationBar" */ '../components/Layout/NotificationBar.vue'
          ),
      },
    },
    // {
    //     path: '/code-exchange',
    //     components: {
    //         default: () => import(/* webpackChunkName: "CodeExchange" */ '../pages/CodeExchange.vue')
    //     }
    // },
    {
      path: '/line-code',
      components: {
        default: () =>
          import(/* webpackChunkName: "LineCode" */ '../pages/LineCode.vue'),
      },
    },
    {
      path: '/policy',
      components: {
        default: () =>
          import(/* webpackChunkName: "Policy" */ '../pages/Policy.vue'),
        NotificationBar: () =>
          import(
            /* webpackChunkName: "NotificationBar" */ '../components/Layout/NotificationBar.vue'
          ),
      },
    },
  ],
};
