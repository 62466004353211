const state = () => ({
  portalInfo: null,
});

const getters = {};

const mutations = {
  setPortalInfo(state, v) {
    state.portalInfo = v;
  },
};

const actions = {
  async init({ state, commit }) {
    if (state.portalInfo) return;

    const res = await getPortalInfo();
    // const res = await dummyPortal();
    console.log('getPortalResData: ', res.data);
    commit('setPortalInfo', res.data);
    setupPortal(res.data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

function getPortalInfo() {
  let params = new URLSearchParams(window.location.search);
  const portalKey = params.get('portal_key');
  const shopId = params.get('shop_id');
  if (!portalKey) throw new Error('no portal key');

  let url = `${process.env.VUE_APP_API_HOST}/stores/v1/portals/${portalKey}`;
  if (shopId) url += `?shop_id=${shopId}`;

  let config = { url, method: 'GET' };

  return qcsso.request(config);
}

function setupPortal(portalInfo) {
  document.title = portalInfo?.title || '';

  const colors = portalInfo?.design?.color || {};
  const root = document.documentElement;
  Object.entries(colors).forEach(([key, value]) => {
    if (!value) return;
    root.style.setProperty(key, value);
  });

  const iconLink = document.createElement('link');
  iconLink.type = 'image/svg';
  iconLink.rel = 'icon';
  iconLink.href = portalInfo?.favicon;
  document.head.appendChild(iconLink);
}

// function dummyPortal() {
//   let result = {
//     name: '鬍鬚張',
//     title: '鬍鬚張',
//     logo: 'https://storage.googleapis.com/gofoodie_assets/img/formosachang.png',
//     favicon:
//       'https://storage.googleapis.com/gofoodie_assets/img/fsmcfavicon.svg',
//     sso: {
//       appId: 'bkjwzyxbkrnyope',
//       inLiffAppId: 'jdowpvwajklvrzb',
//       unverifyAppId: 'jdowpvwajklvrzb',
//     },
//     banners: [
//       {
//         imageUrl:
//           'https://storage.googleapis.com/gofoodie_assets/img/fsmcbanner1.png',
//       },
//       {
//         imageUrl:
//           'https://storage.googleapis.com/gofoodie_assets/img/fsmcbanner2.png',
//       },
//     ],
//     barcode: [
//       { type: 'One-Time-Key', format: 'qrcode' },
//       { type: 'Member-ID', key: 'bcs.lafresh.memberId', format: 'code128' },
//     ],
//     design: {
//       color: { '--k1': '#740008' },
//       services: [
//         {
//           icon: 'fa-solid fa-barcode',
//           name: '會員條碼',
//           key: 'barcodes',
//         },
//         {
//           icon: 'fa-solid fa-map',
//           name: '商店資訊',
//           key: 'stores',
//         },
//         {
//           icon: 'fa-solid fa-newspaper',
//           name: '最新消息',
//           key: 'articles',
//         },
//         {
//           icon: 'fa-solid fa-bell',
//           name: '通知',
//           key: 'notifications',
//         },
//         {
//           icon: 'fa-solid fa-utensils',
//           name: '線上點餐',
//           url: 'https://order-dev.quickclick.cc/tw/food/D_jvAy72l31?p=fsmc&openExternalBrowser=true',
//         },
//         {
//           icon: 'fa-brands fa-line',
//           name: '官方Line',
//           url: 'https://lin.ee/NRTcabS',
//         },
//         {
//           icon: 'fa-solid fa-address-card',
//           name: '帳號設定',
//           key: 'profile',
//         },
//         {
//           icon: 'fa-solid fa-gift',
//           name: '推薦禮',
//           key: 'mgm',
//         },
//       ],
//     },
//     wallet: {
//       points: {
//         enabled: false,
//         name: '米哥點',
//         settings: {
//           pointsToCurrency: {
//             points: 1,
//             currency: 1,
//           },
//           carryMethod: 'ceil',
//           maxDiscountRate: 0.1,
//           disableCash: true,
//           loyaltyProgram: {
//             // 以後如果有分級可能會有別的program, 先放一個基本的purchase
//             purchase: {
//               purchase: 150,
//               accrualAmount: 1,
//             },
//           },
//         },
//       },
//       stamps: {
//         enabled: true,
//         stampCards: [
//           {
//             storeId: 1,
//             stampCardId: 'noq93rkgr8',
//             stampCardName: '雙十一老闆生日回饋',
//             storeName: '美又美早餐店桃園店',
//             loyaltyProgram: {
//               // 以後如果有分級可能會有別的program, 先放一個基本的purchase
//               purchase: {
//                 purchase: 150,
//                 accrualAmount: 1,
//               },
//             },
//             apps: {
//               quickclick: true,
//             },
//           },
//         ],
//       },
//       tickets: {
//         enabled: false,
//       },
//     },
//   };
//   return new Promise(resolve => {
//     setTimeout(() => {
//       resolve({ data: result });
//     }, 300);
//   });
// }
