export default {
  path: '/reward-collection',
  meta: {
    requiresAuth: false,
  },
  components: {
    default: () =>
      import(/* webpackChunkName: "Home" */ '../pages/RewardCollection.vue'),
  },
};
