let getters = {
  getUserPoints(state) {
    return state.userPoints && state.userPoints.length > 0
      ? state.userPoints[0]
      : [];
  },

  getUserTickets(state) {
    const tickets = state.userTickets
      .reduce((acc, product) => {
        const tickets = product.tickets.map((ticket) => {
          const ticketCopy = { ...ticket };
          ticketCopy.title = product.title;
          ticketCopy.description = product.description;
          ticketCopy.storeName = product.storeName;
          ticketCopy.image = product.images[0];
          ticketCopy.useStartAt = product.useStartAt;
          ticketCopy.useExpirationAt = product.useExpirationAt;
          ticketCopy.updatedAt = ticket.updatedTs || ticket.createdTs;
          ticketCopy.duration = product.duration;
          return ticketCopy;
        });
        return (acc = [...acc, ...tickets]);
      }, [])
      .filter((t) => t.status === "pickedup")
      .sort((a, b) => a.updatedAt - b.updatedAt);
    return tickets || [];
  },
  getUserRedeemedTickets(state) {
    const tickets = state.userTickets
      .reduce((acc, product) => {
        const tickets = product.tickets.map((ticket) => {
          const ticketCopy = { ...ticket };
          ticketCopy.title = product.title;
          ticketCopy.description = product.description;
          ticketCopy.storeName = product.storeName;
          ticketCopy.image = product.images[0];
          ticketCopy.useStartAt = product.useStartAt;
          ticketCopy.useExpirationAt = product.useExpirationAt;
          ticketCopy.updatedAt = ticket.updatedTs || ticket.createdTs;
          ticketCopy.duration = product.duration;
          return ticketCopy;
        });
        return (acc = [...acc, ...tickets]);
      }, [])
      .filter((t) => t.status === "redeemed")
      .sort((a, b) => a.updatedAt - b.updatedAt);
    return tickets || [];
  },
  getUserExpiredTickets(state) {
    const tickets = state.userTickets
      .reduce((acc, product) => {
        const tickets = product.tickets.map((ticket) => {
          const ticketCopy = { ...ticket };
          ticketCopy.title = product.title;
          ticketCopy.description = product.description;
          ticketCopy.storeName = product.storeName;
          ticketCopy.image = product.images[0];
          ticketCopy.useStartAt = product.useStartAt;
          ticketCopy.useExpirationAt = product.useExpirationAt;
          ticketCopy.updatedAt = ticket.updatedTs || ticket.createdTs;
          ticketCopy.duration = product.duration;
          return ticketCopy;
        });
        return (acc = [...acc, ...tickets]);
      }, [])
      .filter((t) => t.status === "expired")
      .sort((a, b) => a.updatedAt - b.updatedAt);
    return tickets || [];
  },
  getPointTransactions(state) {
    return state.pointTransactions || {};
  },

  muteNotifications(state) {
    return state.muteNotifications;
  },
};

export default getters;
